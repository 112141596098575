import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, FormHelperText, TextField } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import EmailVerifyToken from '../EmailVerifyToken';
import ApiService from '../../../utils/ApiService';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { loginErrorHandler } from '../../../utils/ApiExceptionHandler';
import { Alert } from '../../widgets/alert';

const LoginJWT = (props) => {
  const { userIpAddress } = useAuth();
  const mounted = useMounted();
  const [entercodestatus, setEnterCodeStatus] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    severity: "error",
    duration: 6000,
    curvature: "24px",
    variant: "standard",
    text: "",
  });
  return (
    <>
        <Alert message={message} handleMessage={setMessage} />
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup
          .object()
          .shape({
            email: Yup
              .string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
            password: Yup
              .string()
              .max(255)
              .required('Password is required')
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            let data = await ApiService.login(values.email, values.password, userIpAddress);

            if (mounted.current) {
              setStatus({ success: true });
              setEmail(values.email);
              if(data?.status === 'Login SuccessFull.') setEnterCodeStatus(true);
              else setEnterCodeStatus(false);
              setSubmitting(false);
              if (data && data instanceof Error) {
                loginErrorHandler(data, setMessage);
                return;
              }
            }
          } catch (err) {
              loginErrorHandler(err, setMessage);
              setStatus({ success: false });
              setEmail('');
              setEnterCodeStatus(false);
              setErrors({ submit: err.message });
              setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            <TextField
              autoFocus
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Senha"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <LoadingButton
                color="primary"
                loading={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Entrar
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
      <EmailVerifyToken status={entercodestatus} email={email}/>

    </>
  );
};

export default LoginJWT;
