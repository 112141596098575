import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import "./i18n";
import SplashScreen from "./components/SplashScreen";
import { gtmConfig } from "./config";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import gtm from "./lib/gtm";
import routes from "./routes";
import { createCustomTheme } from "./theme";

const App = (props) => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const { appServiceWorker } = props;
  appServiceWorker.onInstalled(() =>
    this.setState({ showInstalledMessage: true })
  );
  appServiceWorker.onUpdateFound(() =>
    this.setState({ showUpdateMessage: true })
  );
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    navbarDiscret: settings.navbarDiscret
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <Toaster position="top-center" />
          {auth.isInitialized ? content : <SplashScreen />}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
