export class LocalStorageService {
    
    /**
     * Armazena um item no localStorage da aplicação.
     * @param {string} key 
     * nome/chave para o item a ser armazenado no localStorage 
     * @param {any} value item a ser armazenado 
     * (será convertido em string) 
     */
    static setItem(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * Retorna um item armazenado no localStorage 
     * ou null se o nome/chave informado não existir.
     * @param {string} key nome/chave do item
     * @returns {any|null} item associado ao nome/chave informada
     */
    static getItem(key) {
        return JSON.parse(localStorage.getItem(key));
    }
}
