import axios from "axios";

export const getIpAddress = async () => {
  const urlCall = process.env.REACT_APP_IP_API_URL || 'https://api64.ipify.org';
  const response = await axios.get(urlCall);
  if(response instanceof Error) {
    return "0";
  }
  return response.data;
}
