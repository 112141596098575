import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import AuthorizationRequired from "../pages/AuthorizationRequired";

const RoleBasedGuard = ({ children, role, route }) => {
  const { user } = useAuth();

  let hasPermission = user.user?.group?.id === 1 ? true : false;

  user.permissions.forEach((permission) => {
    if (permission.module?.name === route && permission.description === role) {
      hasPermission = true;
    }
  });

  if (hasPermission) {
    return <>{children}</>;
  }

  return <AuthorizationRequired />;
};

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
};

export default RoleBasedGuard;
