// Importar os componentes necessários do React e do MUI
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

const inputStyle = {
  '& input[type=number]': {
    'WebkitAppearance': 'textfield',
    'MozkitAppearance': 'textfield',
    'appearance': 'textfield'
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    'WebkitAppearance': 'none'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    'WebkitAppearance': 'none'
  }
};

// Definir um estilo personalizado para os campos de texto
const StyledTextField = styled(TextField)`
  width: 2.5rem;
  margin: 0 4px;
`;

// Definir uma constante para o número de dígitos do código de recuperação
const DIGITS_COUNT = 6;

// Criar um componente funcional para o campo de texto de código de recuperação
function RecoveryCodeInput({ value, onChange }) {
  // Criar um estado para armazenar os valores dos dígitos individuais
  const [digits, setDigits] = React.useState(["", "", "", "", "", ""]);
  function handlePaste(event) {
    // Get the pasted data as a string
    var text = event.clipboardData.getData('Text');
    // Split the text into an array of digits
    // Pass the code to the recoveryCodeInput component
    const initialDigits = Array(DIGITS_COUNT).fill('');
    // Se o valor inicial for fornecido, preencher o array com os dígitos correspondentes
    if (text) {
      for (let i = 0; i < DIGITS_COUNT; i++) {
        initialDigits[i] = text[i] || '';
      }
    }
    setDigits(initialDigits);
  }

  React.useEffect(()=>{
    // Inicializar o estado com um array de strings vazias
    const initialDigits = Array(DIGITS_COUNT).fill('');
    // Se o valor inicial for fornecido, preencher o array com os dígitos correspondentes
    if (value) {
      const valueString = value.toString();
      for (let i = 0; i < DIGITS_COUNT; i++) {
        initialDigits[i] = valueString[i] || '';
      }
    }
    setDigits(initialDigits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  React.useEffect(() => {
    // Chamar a função onChange com o novo valor completo
    if (onChange) {
      const nextValue = parseInt(digits.join(''), 10) || 0;
      onChange(nextValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digits]); // Dependência do efeito

  // Criar uma função para lidar com a mudança do valor de um dígito
  const handleDigitChange = (index) => (event) => {
    // Obter o novo valor do campo de texto
    const newValue = event.target.value;
    // Verificar se o novo valor é um dígito válido
    if (/^\d?$/.test(newValue)) {
      // Atualizar o estado com o novo valor
      let nextDigits; // declarar a variável fora do setState
      setDigits((prevDigits) => {
        nextDigits = [...prevDigits]; // atribuir o valor dentro do setState
        nextDigits[index] = newValue;
        return nextDigits;
      });
      // Se o novo valor não for vazio, mover o foco para o próximo campo de texto
      if (newValue) {
        const nextInput = document.getElementById(`recovery-code-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  // Criar uma função para lidar com a tecla pressionada em um campo de texto
  const handleKeyDown = (index) => (event) => {
    // Obter a tecla pressionada
    const { key } = event;
    // Se a tecla for Backspace e o campo de texto estiver vazio, mover o foco para o campo anterior
    if (key === 'Backspace' && !digits[index]) {
      const prevInput = document.getElementById(`recovery-code-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
    // Se a tecla for ArrowLeft e o campo de texto não estiver no início, mover o foco para o campo anterior
    if (key === 'ArrowLeft' && index > 0) {
      const prevInput = document.getElementById(`recovery-code-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
    // Se a tecla for ArrowRight e o campo de texto não estiver no fim, mover o foco para o campo seguinte
    if (key === 'ArrowRight' && index < DIGITS_COUNT - 1) {
      const nextInput = document.getElementById(`recovery-code-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  // Retornar o elemento JSX que representa o campo de texto de código de recuperação
  return (
    <div>
      {digits.map((digit, index) => (
        <StyledTextField
          key={index}
          id={`recovery-code-input-${index}`}
          value={digit}
          onChange={handleDigitChange(index)}
          onPaste={handlePaste}
          type="number"
          sx={inputStyle}
          onKeyDown={handleKeyDown(index)}
          inputProps={{
            maxLength: 1,
            style: { textAlign: 'center' },
          }}
          variant="outlined"
        />
      ))}
    </div>
  );
}

// Definir os tipos das props do componente
RecoveryCodeInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

// Exportar o componente para ser usado em outros arquivos
export default RecoveryCodeInput;
