import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
const ErrorTryAgain = ({ error, resetErrorBoundary }) => {
  const handleClose = () => {
    resetErrorBoundary();
  }
  return (
    <Dialog
      open={true}
    >
      <DialogTitle style={{ color: "red" }}>
        {"Ocorreu um Erro:"}
      </DialogTitle>
      <DialogContent>
        <pre>{error.message}</pre>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="error">Tentar novamente</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
export default ErrorTryAgain;