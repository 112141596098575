import { Box } from "@mui/material";
import Logo from "./Logo";
const SlashScreen = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2000,
        cursor: "wait",
      }}
    >
      <Logo sx={{ width: 260 }} splash/>
    </Box>
  );
};

export default SlashScreen;
