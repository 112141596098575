import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Backdrop, Box, Drawer, Grid, useMediaQuery } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { ModulesContext } from "contexts/ModulesContext";
import useSettings from "hooks/useSettings";

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const { settings } = useSettings();
  const location = useLocation();
  const { user } = useAuth();
  const [username, setUserName] = useState(null);
  const [group, setGroup] = useState(null);
  const context = useContext(ModulesContext);
  const [sections, setSection] = useState([]);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    if (context.section) {
      setSection(context.section);
    }
    if (user.hasOwnProperty("user")) {
      setUserName(user.user.fullName);
      setGroup(user.user.group.name);
    } else {
      setUserName(user.fullName);
      setGroup(user.group.name);
    }
  }, [context, user, username, group, sections]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (!sections.length > 0 || !username || !group) {
    context.getSections();
    return (
      <Backdrop
        sx={{
          color: "#fff",
          cursor: "wait",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <Grid container justifyContent="center" align="center" spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                position: "relative",
                width: 300,
              }}
            >
              <Logo
                sx={{
                  height: 40,
                  width: 300,
                  position: "absolute",
                  opacity: ".9",
                  left: 0,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Backdrop>
    );
  }

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            pt: 3,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 250,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ pt: 3 }}>
          <RouterLink to="/">
            <Logo
              sx={{
                display: {
                  lg: "inline",
                  xs: "none",
                },
                height: 40,
                width: 250,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              settings={settings}
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 2,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: settings.navbarDiscret
              ? "background.default"
              : "neutral.800",
            height: "100%!important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: settings.navbarDiscret
            ? "background.default"
            : "neutral.800",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
