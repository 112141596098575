import {
  IconButton,
  Tooltip,
  useTheme
} from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeIcon from '@mui/icons-material/LightModeTwoTone';
import useSettings from '../../hooks/useSettings';
const ChangeTheme = () => {
  const theme = useTheme();
  const { settings, saveSettings } = useSettings();
  const handleClick = async () => {
    let newMode = '';
    if(theme.palette.mode === 'light') newMode = 'DARK';
    if(theme.palette.mode === 'dark') newMode = 'LIGHT';
    saveSettings({...settings,theme: newMode});
  }
  return <>
    <Tooltip title="Alterar Tema">
      <IconButton color="inherit" onClick={handleClick} size="large">
      {theme.palette.mode === 'light' ? <DarkModeIcon/> : <LightModeIcon/>}
      </IconButton>
    </Tooltip>
  </>;
};

export default ChangeTheme;
