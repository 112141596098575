import { createContext, useState } from "react";

export const LastItemContext = createContext();

export const LastItemProvider = ({ children }) => {
  const [lastitemnumber, setLastitemNumber] = useState(null);
  return (
    <LastItemContext.Provider value={{ lastitemnumber, setLastitemNumber }}>
      {children}
    </LastItemContext.Provider>
  );
};
export default LastItemProvider;
