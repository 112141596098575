/* eslint-disable no-unused-vars */
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from "date-fns/locale";
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import store from "./store";
import ModulesProvider from "contexts/ModulesContext";
import registerServiceWorker from "react-service-worker";
import { ErrorBoundary } from "react-error-boundary";
import ErrorTryAgain from "components/ErrorTryAgain";
import { StyledEngineProvider } from "@mui/material";
import LastItemProvider from "contexts/LastItemContext";
import { LoadingProvider } from "contexts/LoadingContext";
const appSW = registerServiceWorker();
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <>
    {/* <StrictMode> */}
    <HelmetProvider>
      <ErrorBoundary
        FallbackComponent={ErrorTryAgain}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <ReduxProvider store={store}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
              <LoadingProvider>
                <ModulesProvider>
                  <SettingsProvider>
                    <BrowserRouter>
                      <AuthProvider>
                        <LastItemProvider>
                          <App appServiceWorker={appSW} />
                        </LastItemProvider>
                      </AuthProvider>
                    </BrowserRouter>
                  </SettingsProvider>
                </ModulesProvider>
              </LoadingProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ReduxProvider>
      </ErrorBoundary>
    </HelmetProvider>
    {/* </StrictMode> */}
  </>
);
