import { createSlice } from '@reduxjs/toolkit';
import objFromArray from 'utils/objFromArray';
const initialState = {
  activeThreadId: null,
  contacts: {
    byId: {},
    allIds: []
  },
  threads: {
    byId: {},
    allIds: []
  },
  participants: [],
  recipients: []
};
const slice = createSlice({
  name: 'storeReducer',
  initialState,
  reducers: {
    getContacts(state, action) {
      const contacts = action.payload;

      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);

    },
  }
});
export const { reducer } = slice;
export default slice;