import React, { createContext, useEffect, useState, useCallback, useRef } from "react";
import ApiService from "utils/ApiService";
import useMounted from "../hooks/useMounted";
import useAuth from "../hooks/useAuth";

export const ModulesContext = createContext();

export const ModulesProvider = ({ children }) => {
  const [section, setSection] = useState(null);
  const mounted = useMounted();
  const auth = useAuth();
  const controllerRef = useRef(new AbortController());
  const getSections = useCallback(async () => {
    try {
      
      const signal = controllerRef.current.signal;
      const data = await ApiService.getMenus({ signal });
      if (mounted.current  && typeof data !== "undefined") {
        setSection(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      getSections();
      return () => {
        // eslint-disable-next-line
        controllerRef.current.abort(); // Cleanup to cancel any ongoing request
      };
  
    }
  }, [getSections, auth.isAuthenticated]);
  return (
    <ModulesContext.Provider value={{ section, getSections }}>
      {children}
    </ModulesContext.Provider>
  );
};
export default ModulesProvider;
