import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from "prop-types";
import { Alert } from "../components/widgets/alert";
// Apiservice
import ApiService from "../utils/ApiService";
import { LocalStorageService } from "../utils/LocalStorageService";
import { loginErrorHandler } from "../utils/ApiExceptionHandler";
import { getIpAddress } from '../helpers/GetUserIPAddress';
import useMounted from '../hooks/useMounted';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  myToken: "",
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [message, setMessage] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    severity: "error",
    duration: 6000,
    curvature: "24px",
    variant: "standard",
    text: "",
  });
  const [myToken, setMyToken] = useState("");
  const mounted = useMounted();
  const [ userIpAddress, setUserIpAddress ] = useState("");

  const getIp = useCallback(async () => {
    try {
        const data = await getIpAddress();
        if (mounted.current) {
          setUserIpAddress(data);
        }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getIp();
  }, [getIp]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = LocalStorageService.getItem("accessToken");
        if (accessToken) {
          const user = await ApiService.me();
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);

        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, code, ipAddress) => {
    const response = await ApiService.loginStepTwo(email, code, ipAddress);
    if (response && response instanceof Error) {
      loginErrorHandler(response, setMessage);
      return;
    }

    const accessToken = response.jwt;
    const user = response.user;
    setMyToken(accessToken);

    // TODO criar um estado para armazenar as permissoes do usuario
    LocalStorageService.setItem("accessToken", accessToken);
    LocalStorageService.setItem("dimissBanner", true);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("dimissBanner");
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        myToken,
        userIpAddress,
      }}
    >
      {children}

      <Alert message={message} handleMessage={setMessage} />
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
