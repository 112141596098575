
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, experimentalStyled } from '@mui/material';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import ChangeTheme from './ChangeTheme';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: 'rgba(237, 241, 245,.8)',
    backdropFilter: 'blur(6px)',
    boxShadow: 'none',
    color: theme.palette.text.primary
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: 'rgba(11, 15, 25, .8)',
    backdropFilter: 'blur(6px)',
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer - 1
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
          size="large">

          <MenuIcon fontSize="small" />
        </IconButton>

        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 1 }}>
          <ChangeTheme />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
