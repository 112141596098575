
/**
 *
 * @param {Error} error erro na resposta da api.
 * @param {React.Dispatch<React.SetStateAction<object>>} setMessage define mensagem para o toast
 */
export function loginErrorHandler(error, setMessage) {
     //console.error(Object.entries(error))

    // network error
    if (error && error.hasOwnProperty('response') && error.response === undefined) {
        setMessage({
            open: true,
            vertical: 'top',
            horizontal: 'center',
            severity: 'error',
            duration: 5000,
            curvature: '24px',
            variant: 'default',
            text: 'Erro de rede ou servidor indisponível.'
        });
    }

    if (error && error.hasOwnProperty('response') && error.response !== undefined) {
        const { status } = error.response;
        switch(status) {
            case 400:
                setMessage({
                    open: true,
                    vertical: 'top',
                    horizontal: 'center',
                    severity: 'error',
                    duration: 5000,
                    curvature: '24px',
                    variant: 'standard',
                    text: 'Erro e-mail ou senha inválidos.'
                });
                return;
                case 401:
                setMessage({
                    open: true,
                    vertical: 'top',
                    horizontal: 'center',
                    severity: 'error',
                    duration: 5000,
                    curvature: '24px',
                    variant: 'standard',
                    text: 'Erro e-mail ou senha inválidos.'
                });
                return;
                case 403:
                setMessage({
                    open: true,
                    vertical: 'top',
                    horizontal: 'center',
                    severity: 'error',
                    duration: 5000,
                    curvature: '24px',
                    variant: 'error',
                    text: 'Erro e-mail ou senha inválidos.'
                });
                return;
            case 404:
                setMessage({
                    open: true,
                    vertical: 'top',
                    horizontal: 'center',
                    severity: 'error',
                    duration: 5000,
                    curvature: '24px',
                    variant: 'standard',
                    text: 'Erro Recurso não encontrado.'
                });
                return;
            default:
                break;
        }
    }
}
