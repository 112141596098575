import { Icon } from "@mui/material";
//Importar todos os icones utilizados aqui a baixo
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import ChartSquareBarIcon from "@mui/icons-material/DashboardTwoTone";
import LoyaltyIcon from "@mui/icons-material/LoyaltyTwoTone";
import SlideshowIcon from "@mui/icons-material/SlideshowTwoTone";
import GroupIcon from "@mui/icons-material/Diversity2TwoTone";
import CategoryIcon from "@mui/icons-material/CategoryTwoTone";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOnTwoTone";
import ViewModuleIcon from "@mui/icons-material/ViewModuleTwoTone";
import UserIcon from "@mui/icons-material/PermIdentityTwoTone";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import GavelIcon from "@mui/icons-material/GavelTwoTone";
import AutoGraphIcon from "@mui/icons-material/AutoGraphTwoTone";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircleTwoTone";
import LeaderboardIcon from "@mui/icons-material/LeaderboardTwoTone";
import StarsIcon from "@mui/icons-material/StarsTwoTone";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhotoTwoTone";
import AssistantIcon from "@mui/icons-material/AssistantTwoTone";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchangeTwoTone";
import ViewCarouselIcon from "@mui/icons-material/ViewCarouselTwoTone";
import AdUnitsIcon from "@mui/icons-material/AdUnitsTwoTone";
import AcUnitIcon from "@mui/icons-material/AcUnitTwoTone";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferTwoTone";
import LinkIcon from "@mui/icons-material/LinkTwoTone";
import SignpostIcon from "@mui/icons-material/SignpostTwoTone";
import DvrIcon from "@mui/icons-material/DvrTwoTone";
import AlarmIcon from "@mui/icons-material/AccessAlarmTwoTone";
import DisplaySettingsTwoToneIcon from "@mui/icons-material/DisplaySettingsTwoTone";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import NewspaperTwoToneIcon from '@mui/icons-material/NewspaperTwoTone';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import BoyTwoToneIcon from '@mui/icons-material/BoyTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
//fim da lista de importação
//Importante importar o icone e tambem adicionar no array para ser buscado.
//array para adicionar os icones a serem utilizados
const icons = {
  ChartSquareBarIcon: ChartSquareBarIcon,
  StoreIcon: StoreIcon,
  LoyaltyIcon: LoyaltyIcon,
  SlideshowIcon: SlideshowIcon,
  GroupIcon: GroupIcon,
  ViewModuleIcon: ViewModuleIcon,
  MonetizationOnIcon: MonetizationOnIcon,
  UserIcon: UserIcon,
  AdminPanelSettingsIcon: AdminPanelSettingsIcon,
  CategoryIcon: CategoryIcon,
  GavelIcon: GavelIcon,
  AutoGraphIcon: AutoGraphIcon,
  SupervisedUserCircleIcon: SupervisedUserCircleIcon,
  LeaderboardIcon: LeaderboardIcon,
  StarsIcon: StarsIcon,
  AssistantPhotoIcon: AssistantPhotoIcon,
  CurrencyExchangeIcon: CurrencyExchangeIcon,
  AssistantIcon: AssistantIcon,
  ViewCarouselIcon: ViewCarouselIcon,
  AdUnitsIcon: AdUnitsIcon,
  AcUnitIcon: AcUnitIcon,
  SettingsIcon: SettingsIcon,
  LocalOfferOutlinedIcon: LocalOfferOutlinedIcon,
  LinkIcon: LinkIcon,
  SignpostIcon: SignpostIcon,
  DvrIcon: DvrIcon,
  AlarmIcon: AlarmIcon,
  DisplaySettingsTwoToneIcon: DisplaySettingsTwoToneIcon,
  FlagCircleIcon: FlagCircleIcon,
  Newspaper: NewspaperTwoToneIcon,
  ControlPointDuplicateIcon: ControlPointDuplicateIcon,
  AssessmentTwoToneIcon: AssessmentTwoToneIcon,
  BoyTwoToneIcon: BoyTwoToneIcon,
  MonetizationOnTwoToneIcon: MonetizationOnTwoToneIcon,
};
//fim do array.

const IconsCreate = ({ icon }) => {
  return <Icon component={icons[icon]} fontSize="small" />;
};
export default IconsCreate;
