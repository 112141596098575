import { useEffect } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { createCustomTheme } from '../../../theme'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSettings from '../../../hooks/useSettings';

import { Snackbar, Alert as MuiAlert, ThemeProvider } from '@mui/material';

const StyledAlert = styled(MuiAlert)`
    border-radius: ${props => props.curvature || props.theme.shape.borderRadius} !important;
    background-color: ${props => props.theme.palette.primary.main || '#3f51b5'} !important;
    color: ${props => props.theme.palette.primary.contrastText || '#fff'} !important;
    & > .MuiAlert-icon {
        color: ${props => props.theme.palette.primary.contrastText || '#fff'} !important;
    }     
`;

export const Alert = (props) => {
    const { message, handleMessage } = props;
    const { settings } = useSettings();
    const handleClose = () => {
        handleMessage({
            open: false,
            vertical: 'top',
            horizontal: 'center',
            severity: message.severity,
            duration: message.duration,
            curvature: message.curvature || '24px',
            text: ''
        });        
    };

    const theme = createCustomTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        roundedCorners: settings.roundedCorners,
        theme: settings.theme
    });

    useEffect(() => {
        setTimeout(() => {
            handleClose();
        }, message.duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[message.open])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>        
            <Snackbar
                anchorOrigin={{vertical: message.vertical, horizontal: message.horizontal}}
                open={message.open}            
                onClose={handleClose}
                key={message.horizontal + message.vertical}
            >
                <StyledAlert 
                    elevation={6} 
                    variant={message.variant || "standard"}                 
                    severity={message.severity}
                    curvature={message.curvature}
                    theme={theme}
                    onClose={() => handleClose()}
                >
                    {message.text}
                </StyledAlert>
            </Snackbar>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

Alert.propTypes = {
    message: PropTypes.object.isRequired,
    handleMessage: PropTypes.func.isRequired
}

