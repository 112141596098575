import { Alert, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography,Box } from '@mui/material';
import RecoveryCodeInput from '../RecoveryCodeInput';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useAuth from '../../hooks/useAuth';
import useMounted from '../../hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import ApiService from '../../utils/ApiService';

const  EmailVerifyToken = ({status, email}) => {

const mounted = useMounted();
const { login, userIpAddress } = useAuth();
const [valuenumber, setValueNumber] = useState(0);
const [codeSended, setCodeSended] = useState();
const [alert, setAlert] = useState(
  {
    error: false,
    typeError: 'success',
    message: 'Tudo Certo por aqui, Vamos redirecionar você!'
  }
);

  const getRemainingAttempsCode = useCallback(async (email) => {
    try {
      const data = await ApiService.remainingAttempsCode(email);
      if (mounted.current) {
        setCodeSended(data);
      }
      if(data>0&&data<3){
        setAlert({
          error: true,
          typeError: 'error',
          message: 'Código incorreto! Você Tem ' + codeSended + ' Tentativas'
        });
      }
      else if(data===0){
        setAlert({
          error: true,
          typeError: 'error',
          message: 'Você atingiu o numero maximo de tentativas, contate o administrador do sistema'
        });
      }

    } catch (err) {
      console.error(err);
      setAlert({
        error: true,
        typeError: 'error',
        message: 'Você atingiu o numero maximo de tentativas, contate o administrador do sistema'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, email, codeSended]);

  useEffect(() => {
    if(email&&email!==""){
      status && getRemainingAttempsCode(email);
    }
  }, [getRemainingAttempsCode, email, codeSended, status]);
return(
  <Dialog open={status} maxWidth='sm' fullWidth>
  <Formik
    initialValues={{
      email: email,
      code: '',
      ipAddress: userIpAddress,
      submit: null
    }}
    validationSchema={Yup
      .object()
      .shape({
        email: Yup
          .string(),
        code: Yup
          .string(),
        ipAddress: Yup
          .string()
      })}
    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        values.code = valuenumber.toString().padStart(6, '0');
        await login(values.email, values.code, values.ipAddress);
        await getRemainingAttempsCode(email);
        if (mounted.current) {
          setStatus({ success: true });
        }
      } catch (err) {
        console.error(err);
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
      await getRemainingAttempsCode(email);
    }}
  >
    {({handleSubmit, isSubmitting, values }) => (
      <form
        noValidate
        onSubmit={handleSubmit}
      >
        { alert.error && (
          <Box sx={{ mt: 0 }}>
            <Alert severity={alert.typeError}>
            <div>
              {alert.message}
            </div>
            </Alert>
          </Box>
        )}
    <DialogTitle >Verifique Seu Email</DialogTitle>
    <DialogContent>

      <Grid spacing={2} container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="caption">
            Digite o codigo de acesso recebido no seu email
          </Typography>
        </Grid>
        <Grid item md={7} xs={11}>
          <RecoveryCodeInput value={valuenumber} onChange={setValueNumber}/>
        </Grid>

      </Grid>
    </DialogContent>
    <Grid container justifyContent="space-around" my={4}>
      <Grid item xs={10}>
        <LoadingButton
          color="primary"
          loading={isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Verificar
        </LoadingButton>
      </Grid>
      <Grid item xs={12} textAlign="center" sx={{mt: 3}}>
        {// Não recebeu o e-mail? Tente <Button onClick={resendCode.bind(this, values.email, values.ipAddress)}>
           // Reenviar o Código
          //</Button>
        }
        <Divider sx={{my:1}}/>
        <Typography variant="caption" >
          Verifique sua caixa de Spam
        </Typography>
      </Grid>
    </Grid>


      </form>
    )}
  </Formik>
  </Dialog>
)
}






export default EmailVerifyToken;
